<template>
  <div class="events">
    <div v-for="(event,index) in data.slice(1)" :key="index" class="event">
      <!-- <div>{{event}}</div> -->
      <div class="event-icon"><StatusCodeIcon :status="event.statusCode"/></div>
      <div class="event-text">
        <div><b>{{ $filters.datetime(event?.timestamp) }} {{ event?.location?.address?.addressLocality }}</b></div>
        <div v-html="event?.status"></div>
      </div>
      
    </div>
  </div>  
</template>

<script setup>
import {defineProps,toRefs} from 'vue'
import StatusCodeIcon from './StatusCodeIcon.vue'

const props = defineProps({
  data: Array
})
const {data} = toRefs(props)
</script>

<style lang="scss" scoped>
.events {
  display:flex;
  flex-direction: column;
  overflow-x: auto;
  max-width: 100%;
  margin: 0;
  @media only screen and (min-width: 710px) {
    flex-direction: row;
  }
  
}
.event {
  min-width: 17em;
  max-width: 30em;
  /* margin-left: 1em; */
  position: relative;
  padding: 1em 0;
  z-index:1;
  display: grid;
    grid-template-columns: 5em 1fr;
    grid-template-rows: 1fr;
    grid-template-areas:
      "icon text";

  &::before,&:not(:last-of-type)::after {
    content:"";
    border-left: var(--borderWidth) solid var(--mainColor);
    height: 50%;
    position: absolute;
    left: calc(2em - ( var(--borderWidth) / 2 ) );
    z-index: -1;
  }
  &::before {
    top:0;
  }
  &::after {
    bottom:0;
  }

  .event-text {
      width:100%;
  }

  // Horizontale Ansicht
  @media only screen and (min-width: 710px) {
    margin-top: 3em;

    .event-icon {
      margin-top: calc(-3em + ( var(--borderWidth) / 2 ) );;
    }
    display:block;
    .event-text {
      margin-top: 1em;
      padding: 0.5em;
      padding-left: 1em;
      border-right:1px solid var(--shadowColorLight)
    }

    &:not(:last-of-type)::before {
      content:"";
      border:0;
      border-top: var(--borderWidth) solid var(--mainColor);
      width: 100%;
      position: absolute;
      left: 2.75em;
      z-index: -1;
    }
    &:last-of-type .event-text {
      
      border-right: none;
    }

    &::after,&:last-of-type::before {
      display:none;
    }
    
  }
}
.event-icon {
  width: 3em;
  height: 3em;
}

</style>
