<template>
  <div class="footer">
    <div class="footer_title">
      <strong>{{ $t('message.impressum') }}</strong><br>
    © 2021 piké lifestyle GmbH 2020
    
    
    
    </div>
    <div class="footer_block">
      <div>
      piké lifestyle GmbH<br/>
      Max-Liebermann-Straße 4<br/>
      04159 Leipzig<br/>
      Deutschland
      </div>
      <div>
        Telefon: +49 (0)341 910 755 47<br>
        Fax: +49 (0)341 910 755 49  
      </div>
      <div>
        Web: <a href="http://www.atrendo.de" target="_self">http://www.atrendo.de</a><br>
        E-Mail: <a title="E-Mail an den Service schreiben" href="mailto:service@pikee.com">service@pikee.com </a>
      </div>
      <div>
        Geschäftsführer: Dipl.-Kfm. Matthias Kleinke<br>
        Zuständiges Gericht: Amtsgericht Leipzig HRB Nummer: HRB 25294<br>
        USt.Id.: DE265514987
      </div>
    </div>    
  </div>
</template>
<style lang="scss" scoped>
#app a {
  color: white;
}
.footer {
  background-color: var(--shadowColor);
  width: 100%;
  max-width: 100% !important;    
  color: rgb(211, 211, 211);
  padding: 1em;

  

  .footer_title {
    display:block;
    margin-bottom: 1em;
    text-align: center;
    width: 100%;
  }
  
  .footer_block {
    display: flex;
    overflow-x: auto;
    flex-direction: column;
    justify-content: space-between;
    max-width: 1280px;
    margin: 0 auto;
    >* {
      margin: 0 0 1em;
      min-width: 10em;
      max-width: 20em;
    }

    @media only screen and (min-width: 710px) {
      flex-direction: row;
      align-items: center;
    }

    
  }
}
</style>
