<template>
  <div class="return">
    <img src="@/assets/icons/exchange.gif" :alt="$t('message.freeReturn')" :title="$t('message.freeReturn')"/>
    <a :href="'https://retoure.atrendo.de/#text-'+$i18n.locale" target="_blank"><b>{{ $t('message.freeReturn')}}</b></a>
  </div>
</template>
<style lang="scss" scoped>
div.return {
  display:flex;
  flex-direction:row;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width: 710px) {
    justify-content: flex-end;
  }


  >p {
    margin: 0;
  }

  >img{
    width: 2em;
    min-width: 32px;
    height: 2em;
    min-height: 32px;
    margin-right:.5em;
  }
}
</style>
