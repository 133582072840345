<template>
  <div class="atrendo_logo">
    <img  src="@/assets/logos/Logo_Pixirechnung.jpg" />
    <div>
      <p>{{ $t('message.tracking_for') }} <b>{{ trackingId }}</b> <LanguageSwitch :trackingId="trackingId" /></p>
      <div class="tracking-links">
        <p><b>Tracking-Links:</b></p>
        <div>
          <a target="_blank" :href="urls.dhl+trackingId"><img src="@/assets/icons/DHL_Logo.svg" alt="DHL-Tracking" title="DHL-Tracking" /></a>
        </div>
        <div v-if="$i18n.locale == 'fr'">
          <a target="_blank" :href="urls.laPoste+trackingId"><img src="@/assets/icons/La_Poste_Logo.svg" alt="LaPoste-Tracking" title="LaPoste-Tracking" /></a>
        </div>
      </div> 
      <Return />   
    </div>
    
  </div>
  
  <div v-if="apiLoading" class="card loader">    
    <p>{{ $t('message.dhl-loading')}}</p>
    <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
      <path fill="#000" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
        <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite"></animateTransform>
      </path>
    </svg>
  </div>
  <div v-if="apiError" class="card">
    {{ apiError }}
        <p><b>Tracking-Links:</b></p>
        <div>
          <a target="_blank" :href="urls.dhl+trackingId"><img src="@/assets/icons/DHL_Logo.svg" alt="DHL-Tracking" title="DHL-Tracking" /></a>
        </div>
        <div v-if="$i18n.locale == 'fr'">
          <a target="_blank" :href="urls.laPoste+trackingId"><img src="@/assets/icons/La_Poste_Logo.svg" alt="LaPoste-Tracking" title="LaPoste-Tracking" /></a>
        </div>
  </div>
  
  <div v-if="!apiLoading && !apiError" class="card tracking-details">
    <TrackingStatus v-if="dhlData?.data?.status" :data="dhlData.data.status"/>
    <TrackingEvents v-if="dhlData?.data?.events" :data="dhlData.data.events"/>
  </div>
  <Footer />
  
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router'
import { reactive,ref,inject,watch } from 'vue'
import { useI18n } from 'vue-i18n'
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global'
})
import TrackingStatus from '../components/Tracking/Status'
import TrackingEvents from '../components/Tracking/Events'
import Footer from '../components/Footer'
import LanguageSwitch from '../components/Tracking/Language-Switch.vue'
import Return from '../components/Tracking/Return.vue'

var dayjs = require('dayjs')

const route = useRoute()
const router = useRouter()
const trackingId = ref(route?.params?.trackingid)
const axios = inject('axios')
const dhlApi = 'https://tracking.atrendo.de/dhl_api.php?trackingID='

const urls = {
  dhl: 'https://www.dhl.de/de/privatkunden.html?piececode=',
  laPoste: 'https://www.laposte.fr/outils/suivre-vos-envois?code='
}
const dhlData = reactive({})
const apiLoading = ref(false)
const apiError = ref(null)

watch(route, () => {
  getDhlData()
});

getDhlData()
function getDhlData() {
  if(!trackingId) return
  apiLoading.value = true;
  console.log("DHL-API für Sendungsdetails anfragen...")
  Object.assign(dhlData, {})
  axios.get(dhlApi+trackingId.value+"&language="+route?.params?.locale)
    .then(response => {
      if(!response?.data?.data) {
        apiLoading.value = false;
        return
      }

      //Erstelle Fake Versandmeldung

      let newDate = dayjs(response.data.data.events.pop()?.timestamp);
      if(newDate.isValid) {
        newDate = newDate.subtract(173,"minute")?.toString()
        response.data.data.events.push({
          "timestamp": newDate,
          "location": {
              "address": {
                  "addressLocality": "Leipzig, Germany"
              }
          },
          "statusCode": "packed",
          "status": t('message.packed'),
          "description": t('message.packed')
        })
      }
      
      Object.assign(dhlData, response.data)
      apiLoading.value = false;
    })
    .catch(err => {
      apiLoading.value = false;
      apiError.value = t('message.noDhlData')
      console.error(err)
    })
}

</script>
<style lang="scss">
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  >svg {
    width: 2em;
    height: 2em;
  }
  
}
.tracking-links {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin: 0;
  }
  
  >*{
    &:not(:last-child) {      
      margin-bottom: 1em;
      margin-right: .5em;
    }
    img {
      height: 2em;
    }
  }

  @media only screen and (min-width: 710px) {
    flex-direction: row;
    overflow-x:auto;
    justify-content: flex-end;
    align-items: center;
    >*{
      margin-bottom:0 !important;
    }
  }

}
</style>
