<template>
  <div class="tracking-status">
    <div>
      <StatusCodeIcon :status="data.statusCode" class="icon"/>
      <div class="tracking-status-title">
        <h1>{{ data.status }}</h1>
        <p>{{ data?.location?.address?.addressLocality }}, {{ $filters.datetime(data?.timestamp) }} {{ $t('clock') }}</p>
      </div>  
    </div>  
  </div>
</template>

<script setup>
import {defineProps,toRefs} from 'vue'
import StatusCodeIcon from './StatusCodeIcon.vue'



const props = defineProps({
  data: Object
})
const {data} = toRefs(props)
</script>

<style lang="scss" scoped>
.tracking-status {
  position: relative;
  border-bottom: 1px solid var(--shadowColorLight);
  z-index:-1;
  padding-bottom:1em;
  
  >div {
    max-width: 800px;
    display: grid;
    grid-template-columns: 5em 1fr;
    grid-template-rows: 1fr;
    grid-template-areas:
      "icon title";
  }
}
.tracking-status-title {
  align-self: center;
}
h1 {
  margin: 0;
}
p {
  margin: 0;
  color: var(--fontColorLight)
}
.icon{
  &::after {
    content:"";
    border-left: var(--borderWidth) solid var(--mainColor);
    height: 100%;
    position: absolute;
    left: calc(2em - ( var(--borderWidth) / 2 ) );
    bottom: -3.2em;
    z-index: -1;
  }
}
</style>
