<template>
  <select v-model="language" @change="reroute()">
      <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
        {{ lang }}
      </option>
    </select>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { ref } from 'vue'

import { SUPPORT_LOCALES } from '../../i18n'

import {defineProps,toRefs} from 'vue'

const props = defineProps({
  trackingId: String
})
const {trackingId} = toRefs(props)

const langs = SUPPORT_LOCALES 

const i18n = useI18n()
const router = useRouter() 
const language = ref(i18n?.locale?.value);

function reroute() 
{
  router.push({params: { locale: language.value, trackingid: trackingId.value }})
}

</script>

<style lang="scss" scoped>
select {
  text-transform: uppercase;
}
</style>
