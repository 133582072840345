<template>  
  <div class="status-icon-container">
    <div class="status-icon">
      <img  :src="getImgUrl(status)" :title="status" :alt="status">
    </div>
    
  </div>
  
</template>

<script setup>
import {defineProps,toRefs} from 'vue'
const props = defineProps({
  status: String
})
const {status} = toRefs(props)

function getImgUrl(pic) {
  try {
    return require('@/assets/icons/'+pic+'.gif')
  }
  catch(err) {
  }
  
}
</script>
<style lang="scss" scoped>
.status-icon {
  width: 4em;  
  height: 4em;  
  grid-area: icon;
  align-self: center;
  background: var(--cardBackgroundColor);
  border-radius:100%;
  border: 0.4em solid var(--mainColor);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  >*{
    width: 60%;
    min-width:32px;
    height: 60%;
    min-height: 32px;
    /* left: 20%;
    top:20%; */
    position: relative;
    z-index: 100;
    image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  }
}
</style>
